<template>
  <div class="ez-form">
    <form
      ref="ezForm"
      :action="action"
      :method="method"
      :formKey="formKey"
      :id="formKey"
      @submit.prevent="onSubmit">
      <slot name="header"></slot>
      <slot></slot>
      <div class="ez-form__buttons">
        <slot name="buttons"></slot>
      </div>
    </form>
    <ez-loader :show="showLoader && isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { stringify } from 'qs';
import httpService from '@/api/http';
import { parseErrors } from '@/util/response';
import EzLoader from '@/components/ui/Loader';
import { mapGetters } from 'vuex';


/**
 * Custom form that enables centralized error handling.
 * @version 1.0.0
 */
export default {
  components: {
    EzLoader,
  },
  props: {
    /**
     * Method used to submit the form
     */
    method: {
      type: String,
      required: false,
      default: 'post',
      validator: value => ['post', 'get', 'delete', 'put', 'patch'].indexOf(value) !== -1,
    },
    /**
     * Url where should the form be submitted
     */
    action: {
      type: String,
      required: true,
    },
    /**
     * Unique key that is used to get errors from the global state
     */
    formKey: {
      type: String,
      required: true,
    },
    /**
     * Unique key that is used to set loading state
     */
    loadingKey: {
      type: String,
      required: false, // need to check
    },
    /**
     * Determines how should form data be sent to the server
     */
    submitType: {
      type: String,
      required: false,
      default: 'json',
      validator: value => ['json', 'multipart', 'urlencoded'].indexOf(value) !== -1,
    },
    defaultData: {
      type: Object,
      required: false,
      default: null,
    },
    additionalData: {
      type: [Object, FormData],
      required: false,
      default: null,
    },
    additionalHeaders: {
      type: Object,
      required: false,
      default: null,
    },
    showLoader: {
      type: Boolean,
      default: true,
    },
    transformer: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    loading() {
      return this.getLoading(this.loadingKey);
    },
  },
  watch: {
    loading(val) {
      this.isLoading = val;
    },
  },
  methods: {
    /**
     * This will traverse all inputs inside the form.
     * It works by checking all the children and if the component name
     * matches it will call 'callback' function.
     * @param {Function} callback, callback that executes for each field in 'fields'
     * @param {Array} fields, type of fields to call callback on
     * @since 2.0.0
     */
    forEachField(callback, fields = []) {
      const componentsToTraverse = fields.length ? fields : ['ezinput', 'eztextarea', 'ezmultipleimageupload', 'ezimageupload', 'eznumberinput'];
      const components = this.$children
        .filter(child => componentsToTraverse.indexOf(child.$options.name) !== -1);
      components.forEach(callback);
    },
    reset(fields = []) {
      this.forEachField(field => field?.reset?.(), fields);
    },
    clearErrors(fields = []) {
      this.forEachField(field => field?.clearErrors?.(), fields);
    },
    onSubmit() {
      this.toggleSubmitState(true);

      const { submitType, additionalData } = this;
      const formData = new FormData(document.getElementById(this.formKey));
      if (this.defaultData) {
        Object.entries(this.defaultData)
          .forEach(([key, value]) => {
            if (!formData.has(key)) {
              if (Array.isArray(value)) {
                if (value.length) {
                  value.forEach(item => formData.append(`${key}[]`, item));
                } else {
                  // eslint-disable-next-line no-unused-expressions
                  submitType === 'json' && formData.append(`${key}[]`, '');
                }
              } else if (value instanceof Object) {
                formData.append(key, JSON.stringify(value));
              } else {
                formData.append(key, value);
              }
            }
          });
      }

      if (additionalData !== null) {
        // If FormData is sent join them.
        if (additionalData instanceof FormData) {
          // eslint-disable-next-line no-restricted-syntax
          for (const field of additionalData.entries()) {
            formData.append(field[0], field[1]);
          }
        } else {
          Object.entries(additionalData)
            .forEach(([key, val]) => {
              formData.append(key, val);
            });
        }
      }

      const formTypeMappings = {
        urlencoded: 'application/x-www-form-urlencoded',
        multipart: 'multipart/form-data',
        json: 'application/json',
      };

      const formDataToObject = (data) => {
        const jsonData = {};
        let e;
        let key;
        let value;
        // eslint-disable-next-line no-cond-assign
        for (let es = data.entries(); !(e = es.next()).done && ([key, value] = e.value);) {
          if (key.slice(-2) === '[]') {
            const prop = key.replace('[]', '');
            if (!jsonData[prop]) {
              jsonData[prop] = [];
            } else {
              jsonData[prop].push(value);
            }
          } else if (!jsonData[key]) {
            jsonData[key] = value;
          } else if (Array.isArray(jsonData[key])) {
            jsonData[key].push(value);
          } else {
            jsonData[key] = [jsonData[key], value];
          }
        }
        return jsonData;
      };

      let data = null;

      switch (submitType) {
        case 'multipart':
          data = formData;
          break;
        case 'json':
          data = formDataToObject(formData);
          break;
        case 'urlencoded':
          data = stringify(formDataToObject(formData));
          break;
        default:
          break;
      }

      const transformData = () => {
        if (!this.transformer) return data;
        return this.transformer(data);
      };
      data = transformData();

      const options = {
        method: this.method,
        url: this.action,
        headers: {
          ...this.loadingKey && { loadingKey: this.loadingKey },
          formKey: this.formKey,
          'Content-Type': formTypeMappings[this.submitType],
          ...this.additionalHeaders,
        },
        data,
      };

      httpService(options)
        .then((response) => {
          this.$emit('success', response);
        })
        .catch((error) => {
          const errorFields = this.$children
            .filter(child => child.hasErrorMessage || child.hasServerErrorMessage);
          const [firstField] = errorFields;
          if (firstField?.$el) firstField.$el.scrollIntoView();
          this.$emit('error', parseErrors(error.response));
        })
        .finally(() => {
          this.toggleSubmitState(false);
        });
    },

    toggleSubmitState(state) {
      if (!this.$refs.ezForm || !this.showLoader) {
        return;
      }

      Object.entries(this.$refs.ezForm.querySelectorAll('.ez-form__buttons button'))
        .forEach(([, button]) => {
          const buttonType = button.getAttribute('type')
            .toLowerCase();

          if (buttonType !== 'submit') {
            return;
          }

          if (state) {
            this.isLoading = true;
            button.setAttribute('disabled', 'disabled');

            return;
          }

          this.isLoading = false;
          button.removeAttribute('disabled');
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .ez-form {

    .form-help {
      @extend %form-help;
    }
  }
  :deep() .loader {
    position: fixed;
  }
</style>
